<template>
  <div class="row">
    <div class="flex xs12">
      <va-card
        class="goals-widget mb-4"
        :title="title"
      >
        <div slot="actions">
          <va-select
            v-model="selectedPeriod"
            :label="$t('layout.form.yearInput')"
            :options="periodList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
        </div>
        <va-inner-loading :loading="loading">
          <div class="row justify-content-center">
            <div
              class="flex xs12 sm6"
              v-for="goal of goals.slice(0, 2)"
              :key="goal.type"
            >
              <div class="row">
                <div class="flex xs12">
                  <circle-chart
                    :count="goal.count"
                    :size="100"
                    :goal-data="goal.data"
                    :label="$t(goal.title)"
                    :color="goal.palette"
                    text="%"
                  />
                </div>
                <div class="flex xs12">
                  <h6 class="chart-title text-center">{{ $t(goal.title) }}</h6>
                </div>
              </div>
            </div>
            <div class="flex xs12" v-show="!loading && goals.length === 0">
              <h2>{{ $t('layout.dashboard.no_goals') }}</h2>
            </div>
          </div>
        </va-inner-loading>
      </va-card>
    </div>
    <div class="flex xs12">
      <va-card
        class="goals-widget"
        v-show="!(!loading && goals.length < 2)"
      >
        <va-inner-loading :loading="loading">
          <div class="row justify-content-center">
            <div
              class="flex xs12 sm4"
              v-for="goal of goals.slice(2)"
              :key="goal.type"
            >
              <div class="row">
                <div class="flex xs12">
                  <circle-chart
                    :count="goal.count"
                    :size="50"
                    :goal-data="goal.data"
                    :label="$t(goal.title)"
                    :color="goal.palette"
                    text="%"
                  />
                </div>
                <div class="flex xs12">
                  <h6 class="chart-title text-center">{{ $t(goal.title) }}</h6>
                </div>
              </div>
            </div>
          </div>
        </va-inner-loading>
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const CircleChart = () => import(/* webpackPrefetch: true */ '../statistics/charts/CircleChart')

export default {
  name: 'dashboard-goals-charts',
  components: { CircleChart },
  data () {
    return {
      periodYear: 0,
      loading: false,
      error: false,
      rawGoals: [],
      goals: [],
      years: [],
      title: '',
      selectedPeriod: null,
      periodList: [],
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
  },
  watch: {
    currentLocale (val) {
      this.initTitle()
    },
    '$themes.primary' (val) {
      if (this.rawGoals.length) this.goals = this.fixGoals(this.rawGoals)
    },
    selectedPeriod (val) {
      this.checkPeriod()
    },
  },
  created () {
    this.initYears()
    this.initTitle()
    this.getGoalsData()
  },
  methods: {
    fixGoals (data) {
      const goals = []

      const predication = data.find(d => d.type === 0)
      if (predication) {
        goals.push({
          title: 'dashboard.predication',
          palette: this.$themes.primary,
          type: 0,
          count: predication.count,
          total: predication.total,
          data: predication.data,
        })
      }

      const mission = data.find(d => d.type === 1)
      if (mission) {
        goals.push({
          title: 'dashboard.missions',
          palette: this.$themes.success,
          type: 1,
          count: mission.count,
          total: mission.total,
          data: mission.data,
        })
      }

      const trainings = data.find(d => d.type === 2)
      if (trainings) {
        goals.push({
          title: 'dashboard.trainings',
          palette: this.$themes.primary,
          type: 2,
          count: trainings.count,
          total: trainings.total,
          data: trainings.data,
        })
      }

      const instructors = data.find(d => d.type === 3)
      if (instructors) {
        goals.push({
          title: 'dashboard.instructors',
          palette: this.$themes.success,
          type: 3,
          count: instructors.count,
          total: instructors.total,
          data: instructors.data,
        })
      }

      const plantings = data.find(d => d.type === 4)
      if (plantings) {
        goals.push({
          title: 'dashboard.plantings',
          palette: this.$themes.danger,
          type: 4,
          count: plantings.count,
          total: plantings.total,
          data: plantings.data,
        })
      }

      return goals
    },
    initTitle () {
      const periodYear = this.selectedPeriod.id
      this.title = this.$t('dashboard.goals.header', { year: periodYear, nextYear: Number(periodYear) + 1 })
    },
    initYears () {
      const firstYear = 2019
      const date = new Date()
      const month = date.getMonth() + 1
      let year = date.getFullYear()
      if (month < 11) {
        year -= 1
      }
      const periods = []
      let currentPeriod = ''
      for (let i = firstYear; i <= year; i++) {
        const name = `${i}-${i + 1}`
        const period = {
          name: name,
          id: i,
        }
        if (i === year) currentPeriod = period
        periods.push(period)
      }

      this.periodList = periods.slice(0)
      this.selectedPeriod = currentPeriod
    },
    checkPeriod () {
      const date = new Date()
      const month = date.getMonth() + 1
      let year = date.getFullYear()
      if (month < 11) {
        year -= 1
      }
      const periodYear = this.selectedPeriod.id

      if (periodYear > year) {
        this.showToast(this.$t('layout.dashboard.no_period', { year: year }), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return
      }
      this.initTitle()
      this.getGoalsData()
      this.$store.commit('updatePeriod', periodYear)
    },
    async getGoalsData () {
      this.loading = true
      this.error = false
      const periodYear = this.selectedPeriod.id
      let d = null
      try {
        d = await this.$http.get('goals/numbers?year=' + periodYear)
      } catch (e) {
        this.loading = true
        return
      }

      this.rawGoals = d.data.data
      this.goals = this.fixGoals(this.rawGoals)
      this.loading = false

      if (this.goals.length === 0) {
        this.$emit('empty')
      } else {
        this.$emit('full')
      }
    },
  },
}
</script>

<style lang="scss">
.align-self-center {
  align-self: center;
}

.goals-widget {
  span {
    float: right;
  }

  .chart-title {
    padding-top: 20px;
  }
}
</style>
